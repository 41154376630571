<template>
  <div>
    <div>
      <b-form-checkbox v-model="checked" name="Language" switch>
        Switch Language {{ checked ? '(Germany)' : '(English)' }}</b-form-checkbox
      >
      <!-- <b-form-input v-model="title" placeholder="Title" style="margin-bottom: 10px; margin-top: 10px"></b-form-input> -->
      <div style="display: flex; flex-direction: row; margin-top: 10px; margin-bottom: 10px">
        <div style="flex: 50%; padding: 10px">
          <b-form-input v-model="title" placeholder="Title"></b-form-input>
        </div>
        <div style="flex: 50%; padding: 10px">
          <b-form-input v-model="icon" placeholder="Icon"></b-form-input>
          <a href="https://fonts.google.com/icons" target="_blank">https://fonts.google.com/icons</a>
        </div>
      </div>
    </div>
    <b-card footer-tag="footer" footer-class="text-right">
      <b-row>
        <b-col xl="12" lg="12" md="12" sm="12" cols="12"> <ckeditor v-model="editorData"></ckeditor></b-col>
      </b-row>

      <template #footer>
        <b-button variant="primary" class="font-weight-bold px-10" @click="save()"> Save </b-button>
      </template>
    </b-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import CKEditor from 'ckeditor4-vue'
import DisclaimerServices from '../../../core/services/disclaimers'

export default {
  name: 'disclaimers-data-protection',
  props: ['id'],
  mounted() {
    console.log('this.id', this.id)
    console.log(`this.test`, this.test)
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Disclaimers' }])
    this.get()
  },
  components: {
    ckeditor: CKEditor.component,
  },
  data() {
    return {
      show: 'dataProtection',
      editorData: '',
      title: '',
      checked: false,
      data: [],
      disclaimerId: this.id,
      icon: '',
    }
  },
  methods: {
    async save() {
      if (this.checked) {
        // Germany
        const DisclaimerType = {
          htmlCodeEN: this.data.htmlCodeEN,
          htmlCodeDE: this.editorData,
          titleEN: this.data.htmlCodeEN,
          titleDE: this.title,
          icon: this.icon,
        }
        const { data } = await DisclaimerServices.updateDisclaimers(this.id, DisclaimerType)
        console.log(`data`, data)
        this.editorData = data.htmlCodeEN
        this.title = data.titleEN
        this.data = data
        return data
      }

      const DisclaimerType = {
        htmlCodeDE: this.data.htmlCodeDE,
        htmlCodeEN: this.editorData,
        titleEN: this.title,
        titleDE: this.data.titleDE,
        icon: this.icon,
      }

      const { data } = await DisclaimerServices.updateDisclaimers(this.id, DisclaimerType)

      console.log(`data`, data)
      this.editorData = data.htmlCodeEN
      this.title = data.titleEN
      this.data = data
    },
    async get() {
      const { data } = await DisclaimerServices.get(this.$route.params.id)
      this.editorData = data.htmlCodeEN
      this.title = data.titleEN
      this.icon = data.icon
      this.data = data
    },
  },
  watch: {
    checked: function (oldVal, newVal) {
      if (!newVal) {
        return (this.editorData = this.data.htmlCodeDE), (this.title = this.data.titleDE)
      }
      this.editorData = this.data.htmlCodeEN
      this.title = this.data.titleEN
    },
    id: async function (oldVal, newVal) {
      console.log(`id newVal`, newVal)
      const { data } = await DisclaimerServices.get(this.$route.params.id)
      this.editorData = data.htmlCodeEN
      this.data = data
      this.title = data.titleEN
      this.icon = data.icon
    },
  },
}
</script>
